export const plpTypes = {
  engagementRing: "engagement-ring",
  default: "default",
};

export const staticProductCategoriesSlugs = {
  engagement: "engagement",
  diamonds: "diamonds",
};

export const filterQueryKey = {
  range_materials: "range_materials",
  materials: "materials",
  price: "price",
  style: "style",
  style_collections: "style_collections",
  style_categories: "style_categories",
  collections: "collections",
  categories: "categories",
};

export const startWithTypes = {
  product: "product",
  diamond: "diamond",
};

export const stripePaymentMethodNames = {
  [paymentMethods.affirm_express]: "confirmAffirmPayment",
  [paymentMethods.affirm]: "confirmAffirmPayment",
  [paymentMethods.klarna]: "confirmKlarnaPayment",
};

export const propertiesGroupSkus = {
  shape: "SHAPE",
  totalCaratWeight: "TOTAL_CARAT_WEIGHT",
  stoneSize: "STONE_SIZE",
  style: "STYLE",
  designCategory: "DESIGN_CATEGORY",
  metalColor: "COLOR",
  metalCaratWeight: "METAL_CARAT_WEIGHT",
  ringSize: "RING_SIZE",
  stoneType: "STONE_TYPE",
  metal: "METAL",
  caratWeight: "CARAT_WEIGHT",
};

export const propertiesGroupSlugs = {
  shape: "shape",
  totalCaratWeight: "total-carat-weight",
  stoneSize: "stone-size",
  style: "style",
  designCategory: "design-category",
  metalColor: "color",
  metalCaratWeight: "metal-carat-weight",
  ringSize: "ring-size",
  stoneType: "stone-type",
};

export const validFileExtensions = {
  image: ["image/jpg", "image/jpeg", "image/png"] as string[],
} as const;

export const diamondSku = "DIAMONDS";
export const stoneSizeSku = "STONE_SIZE";
export const ringSizeSku = propertiesGroupSkus.ringSize;
export const diamondCaratSku = "DIAMOND_CARAT";
export const settingCaratWeightSlug = "setting-carat-weight";
export const diamondCaratWeightSlug = "diamond-carat";
export const stoneTypeSku = "STONE_TYPE";

export const diamondMaterialsShowListSKU = [
  { sku: "DIAMOND_CUT", order: 1 },
  { sku: "DIAMOND_COLOR", order: 2 },
  { sku: "DIAMOND_CLARITY", order: 3 },
  // { sku: "DIAMOND_LABORATORY", order: 4 },
];

export const settingsMaterialShowListSKU = [
  { sku: propertiesGroupSkus.style, order: 1 },
  { sku: propertiesGroupSkus.metal, order: 2 },
];

export const cartMaterialShowListSKU = [
  { sku: propertiesGroupSkus.stoneSize, order: 1 },
  { sku: propertiesGroupSkus.caratWeight, order: 1 },
  { sku: propertiesGroupSkus.metal, order: 2 },
  // { sku: "RING_STYLE", order: 2 },
  // { sku: "RING_SIZE", order: 3 },
  // { sku: "JEWELRY_TYPE", order: 3 },
  // { sku: "CUT", order: 4 },
];

export const sizesSKUs = [
  propertiesGroupSkus.ringSize,
  "NECKLACE_SIZE",
  "BRACELET_SIZE",
];
export const sizesSlugs = ["ring-size", "necklace-size", "bracelet-size"];

export const DIAMOND_CERTIFICATES_OPTIONS = {
  IGI: { SKU: "D_LAB_IGI", IMAGE: "/images/IGI_cert.webp" },
  GIA: { SKU: "D_LAB_GIA", IMAGE: "/images/GIA_cert.webp" },
};

export const CACHE_API_ENDPOINTS = {
  bootstrap: "system_settings",
  "system-catalog": "product",
  products: "product",
  collections: "collection",
  pages: "page",
  articles: "posts",
  blog: "posts",
  posts: "posts",
  "system-entities": "page",
  blocks: "page",
  catalog: "product",
  filters: "product",
  popups: "page",
  "product-configurations": "product",
  "campaign-discount": "page",
  "payment-gateways": "payment_gateways",
  "payment-methods": "payment_methods",
} as const;

export const EVENTS_ID = {
  VISIT: "visit",
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  ADD_PAYMENT_INFO: "add_payment_info",
  ADD_SHIPPING_INFO: "add_shipping_info",
  CHECKOUT_ERROR: "checkout_error",
  PURCHASE: "purchase",
  SEARCH: "search",
  ADD_TO_FAVORITES: "add_to_favorites",
  REMOVE_FROM_FAVORITES: "remove_from_favorites",
  REMAIN: "remain",
};

export const bannerSize = {
  "1x1": "1x1",
  "1x2": "1x2",
  "1x4": "1x4",
  "2x2": "2x2",
};
